import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["overlay", "content"]

  connect() {
    // Read data attributes from element
    this.triggerType = this.data.get("triggerType")
    this.triggerId = this.data.get("triggerId")
    this.triggerDelay = parseInt(this.data.get("triggerDelay") || "0", 10)

    // Manual trigger via links/buttons:
    document.querySelectorAll(`a[href="#openOfferModal${this.triggerId}"], button[data-trigger="#openOfferModal${this.triggerId}"]`).forEach(element => {
      element.addEventListener('click', event => {
        event.preventDefault()
        this.open()
      })
    })

    // Listen for a global event to open this specific modal
    document.addEventListener('openOfferModal', (event) => {
      if (event.detail && event.detail.id === this.triggerId) {
        this.open()
      }
    })

    // Timed trigger:
    if (this.triggerType === "timed") {
      this.timeoutId = setTimeout(() => {
        this.open()
      }, this.triggerDelay * 1000)
    }
  }

  disconnect() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  open() {
    this.element.classList.add('is-visible')
  }

  close() {
    this.element.classList.remove('is-visible')
  }
}
